import React, {Component} from 'react';
import CodeMirror from 'react-codemirror';
import formatXml from 'xml-formatter';
import SVGO from 'svgo';

import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';

import './App.css';
import * as svgpath from 'svgpath';
import svgoConfig from './svgoConfig';

const formatOptions = {indentation: '  '};
const svgo = new SVGO(svgoConfig);

class App extends Component {
  componentDidMount() {
    this.setOriginalSvg('<svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="m6 12h28v2.074h-28zm0 7.26h28v2.073h-28zm0 7.259h28v2.074h-28z"/><mask id="b" fill="#fff"><use fill="none" xlink:href="#a"/></mask></defs><g fill="none" fill-rule="evenodd"><use fill="#000000" xlink:href="#a"/><g mask="url(#b)"><path d="m0 0h40v40h-40z" fill="#000"/></g></g></svg>')
  }

  state = {
    originalSvg: undefined,
    modifiedSvg: undefined,
    showGrid: true,
    gridSize: 1,
    precision: 0,
  };

  setOriginalSvg = xml => {
    let formattedXml = formatXml(xml, formatOptions);
    this.setState({
      originalSvg: formattedXml,
      modifiedSvg: formattedXml,
    });
  };

  onFileChange = event => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = event => {
      this.setOriginalSvg(event.target.result);
    };
    reader.readAsText(file);
  };

  onEditorChange = string => {
    this.setState({modifiedSvg: string});
  };

  apply = () => {
    this.setState({originalSvg: this.state.modifiedSvg})
  };

  format = () => {
    this.setModified(formatXml(this.state.modifiedSvg, formatOptions));
  };

  roundPaths = (n) => {
    const el = toSvgElement(this.state.modifiedSvg);
    Array.from(el.getElementsByTagName('path')).forEach(path => {
      path.attributes.d.value = svgpath(path.attributes.d.value).round(n).toString();
    });
    this.setModified(el.outerHTML)
  };

  optimize = async () => {
    const res = await svgo.optimize(this.state.modifiedSvg, {path: ''});
    this.setModified(res.data)
  };

  setModified = modifiedSvg => {
    this.setState({modifiedSvg});
    this.codeMirror.getCodeMirror().setValue(modifiedSvg);
  };

  render() {
    const {gridSize, modifiedSvg, originalSvg, precision, showGrid} = this.state;
    return (
      <div className="App">
        <div className="editor">
          <div className="editor__buttons">
            <button onClick={this.format}>Format</button>
            <button onClick={this.optimize}>Optimize</button>
            <button onClick={() => this.roundPaths(precision)}>Round Paths</button>
            <label> to precision:
              <input type="number" value={precision} onChange={e => this.setState({precision: parseInt(e.target.value, 10)})}/>
            </label>
            <label>
              Open file
              <input type="file" onChange={this.onFileChange}/>
            </label>
          </div>
          <CodeMirror
            ref={ref => {this.codeMirror = ref}}
            value={modifiedSvg}
            onChange={this.onEditorChange}
            options={{mode: 'xml', lineNumbers: true, lineWrapping: true}}
          />
        </div>
        <div className="preview">
          <p>Before: <button onClick={this.apply}>Apply</button></p>
          <div className="preview__image">
            <img
              className="preview__svg preview__svg--original"
              width="300"
              height="300"
              src={toDataUri(originalSvg)}
              alt="Before"
            />
            {validSvg(this.state.originalSvg) && showGrid && gridSize > 0 && (
              <img
                className="preview__grid"
                width="300"
                height="300"
                src={toDataUri(makeGrid(gridSize, getDimensions(originalSvg)))}
                alt="Grid overlay"
              />
            ) }
          </div>
          <p>After:</p>
          <div className="preview__image">
            <img
              className="preview__svg preview__svg--modified"
              width="300"
              height="300"
              src={validSvg(this.state.modifiedSvg) ? toDataUri(modifiedSvg) : ''}
              alt="After"
            />
            {validSvg(this.state.modifiedSvg) && showGrid && gridSize > 0 && (
              <img
                className="preview__grid"
                width="300"
                height="300"
                src={toDataUri(makeGrid(gridSize, getDimensions(modifiedSvg)))}
                alt="Grid overlay"
              />
            )}
          </div>
          <div className="options">
            <label>
              Show Grid:
              <input type="checkbox" checked={showGrid} onChange={() => this.setState({showGrid: !showGrid})}/>
            </label>
            <br/>
            <label>
              Grid Pixel Size:
              <input type="number" value={gridSize} onChange={e => this.setState({gridSize: parseInt(e.target.value, 10)})}/>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

function attr(el, n) {
  return el.attributes[n] ? el.attributes[n].value : undefined
}

function validSvg(svgString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, "application/xml");
  return doc.documentElement.tagName === 'svg';
}


function toSvgElement(svgString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, "application/xml");
  return doc.documentElement;
}

function getDimensions(svg) {
  const el = toSvgElement(svg);
  const viewbox = (attr(el, 'viewBox') || '').split(' ');
  const width = parseInt(viewbox[2] || attr(el, 'width'), 10);
  const height = parseInt(viewbox[3] || attr(el, 'height'), 10);
  return {height, width}
}

function makeGrid(pixels, {width, height}, color = "#00000040") {
  let body = '';
  for (let i = 1; i < width / pixels; i++) {
    const x = 300 * i * pixels / width;
    body += `<line x1="${x}" y1="0" x2="${x}" y2="300" stroke="${color}"/>`
  }
  for (let i = 1; i < height / pixels; i++) {
    const y = 300 * i * pixels / height;
    body += `<line x1="0" y1="${y}" x2="300" y2="${y}" stroke="${color}"/>`
  }
  return `<svg height="300" width="300" xmlns="http://www.w3.org/2000/svg">${body}</svg>`
}

const toDataUri = svg => `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;

export default App;
